export const Info = {
  name: "Pacha Mamma Massage",
  hours: "1:00 PM - 6:00 PM Wednesday to Friday",

  tel1: "403-805-8256",
  mail1: "kathryn@bookwithkathryn.com",

  street1: "112196 266 Ave W, T1S 3B4",
  city1: "Foothills, AB",
}
